import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CandidateService } from './candidate.service';
import { EditorModule } from 'primeng/editor';
import { LookupsService } from '@app/lookups/lookups.service';

@Component({
  selector: 'dcar-candidate-detail',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, EditorModule],
  templateUrl: './candidate-detail.component.html',
  styleUrl: './candidate-detail.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CandidateDetailComponent {
  @ViewChild('candForm') candidateDetailForm

  controlsDisabled = true;
  countries: any[] = [];
  states: any[] = [];

  constructor(
    public _candidateService: CandidateService,
    private _lookupsService: LookupsService
  ) {
    this._candidateService.candidateDetailForm.get('country').valueChanges.subscribe((value) => {
      console.log('value',value);
      this.getStates(value);
    });
  }

  ngOnInit(): void {

    this._lookupsService.getAllCountries().subscribe((result) => {
      this.countries = result;
    });
  }

  getStates(countryCode) {
    //const countryCode = this._candidateService.candidateDetailForm.get('country')?.value;
    const countryId= this.countries.find(c => c.code === countryCode)?.id;
    
    this._lookupsService.states(countryId).subscribe((result) => {
      this.states = result;
      console.log(this.states);
    });
  }

  //onSubmit(): void {
    //console.log(this._candidateService.candidateDetailForm.value);
  //}

  toggleDiabled() {
    this.controlsDisabled = !this.controlsDisabled
  }

  submitForm() {
    this.candidateDetailForm.nativeElement.submit();
  }
}
