// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
import { HttpHeaders } from "@angular/common/http";

export const environment = {
    production: false,
    hmr: false,
    appConfig: 'appconfig.json',
    remoteApiBaseUrl: 'https://wc-api.ntslabs-unitek.com/',
    remoteApiHeaders: new HttpHeaders().set('Content-Type', 'application/json').set('x-api-key', 'ca03na188ame03u1d78620de67282882a84'),
    azureBlobHeaders: new HttpHeaders().set('Content-Type', 'image/webp').set('x-api-key', 'ca03na188ame03u1d78620de67282882a84'),
    azurePdfHeaders: new HttpHeaders().set('Content-Type', 'application/pdf').set('x-api-key', 'ca03na188ame03u1d78620de67282882a84'),
    azureDocHeaders: new HttpHeaders().set('Content-Type', 'application/msword').set('x-api-key', 'ca03na188ame03u1d78620de67282882a84'),
    multiPartApiHeaders: new HttpHeaders().set('x-api-key', 'ca03na188ame03u1d78620de67282882a84'),
    filemanagerapi: 'https://file.nts-launchpad.com/api/',
    filemanagerApiHeaders: new HttpHeaders().set('Content-Type', 'application/json').set('ApiKey', 'c59852a2-7f08-49e2-8541-e66c6e9be235'),
    googleMapsApiKey: 'AIzaSyARTOyKD0P8moMoVRT64dn7JAnW-eZxm4g'
};
