import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CandidateList } from './_models/candidate-list.interface';
import { HttpBaseService } from '@app/shared/_services/http-base.service';
import { CandidateTechPackList } from './_models/candidate-tech-pack-list.interface';
import { CandidateDetail } from './_models/candidate-detail.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { resume } from '@app/_databases/_entities/resume.entity';
import { CandidateChecklist } from './_models/candidate-checklist.interface';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  selectedCandidateName: string = '';
  selectedCandidateId: string = '';
  selectedCandidate: CandidateDetail = new CandidateDetail();
  candidateDetailForm: FormGroup;
  candidateChecklistForm: FormGroup;
  techPackRequestedByName: string = '';
  

  

  constructor(
    private _httpBaseService: HttpBaseService,
    private fb: FormBuilder,
    //private cdr: ChangeDetectorRef
  ) { 
    this.candidateDetailForm = this.fb.group({
      id: [''],
      ssn: [''],
      first_name: [''],
      last_name: [''],
      add1: [''],
      add2: [''],
      city: [''],
      state: [''],
      zip: [''],
      country: [''],
      phone: [''],
      cell: [''],
      pager: [''],
      fax: [''],
      email: [''],
      resume_text: [''],
      tech_pack: [''],
      requested_by_id: [''],
      date_requested: [''],
      tech_pack_link: [''],
      date_sent: [''],
      status: [''],
      comments: [''],
      requested_by_name: ['']
    }); 

    this.candidateChecklistForm = this.fb.group({
      id: [''],
      delivery_type: [''],
      date_techpack_sent: [''],
      date_of_birth: [''],
      social_security_number: [''],
      citizen_status: [''],
      signed_application_received: [false],
      signed_application_received_date: [''],
      code_of_ethics_received: [false],
      code_of_ethics_received_date: [''],
      nda_received: [false],
      nda_received_date: [''],
      registration_agreement_received: [false],
      registration_agreement_received_date: [''],
      ethical_practices_received: [false],
      ethical_practices_received_date: [''],
      i9_received: [false],
      i9_received_date: [''],
      support_docs_notarized: [false],
      support_docs_notarized_date: [''],
      w4_received: [false],
      w4_received_date: [''],
      resume_received: [false],
      resume_received_date: [''],
      professional_references_received: [false],
      professional_references_received_date: [''],
      previous_employment_history_received: [false],
      previous_employment_history_received_date: [''],
      higher_education_verified: [false],
      higher_education_verified_date: [''],
      how_higher_education_verified: [''],
      university_representitive_name: [''],
      certifications_verified: [false],
      certifications_verified_date: [''],
      direct_deposit_form_received: [false],
      direct_deposit_form_received_date: [''],
      ts_number_requested: [false],
      ts_number_requested_date: [''],
      assigned_ts_number: [''],

    });
  }

  getCandidateDetail(data: any) {
    
    this.callGetCandidateDetail(data).subscribe((result) => {
      this.candidateDetailForm.patchValue(result);
      this.techPackRequestedByName = result.requested_by_name != ''? result.requested_by_name : null;
    });

    this.callGetCandidateChecklist().subscribe((result) => {
      this.candidateChecklistForm.patchValue(result);
    });
  }
  
  callGetCandidateDetail(data: any): Observable<CandidateDetail> {
    console.log('fired');
    const id = data.id;
    this.selectedCandidateId = id;
    this.selectedCandidateName = `${data.last_name}, ${data.first_name}`;

    return this._httpBaseService.get(`dcar/get-candidate-detail/${id}`)
  }

  callGetCandidateChecklist(): Observable<CandidateChecklist> {
    return this._httpBaseService.post(`dcar/get-candidate-checklist`, {id: this.selectedCandidateId});
  }

  getCandidateList(): Observable<CandidateList[]> {
    return this._httpBaseService.get(`dcar/get-candidates`);
  }
  
  getCandidateTechPackList(): Observable<CandidateTechPackList[]> {
    return this._httpBaseService.get(`dcar/get-candidate-tech-pack-list`);
  }

  
}