<div class="n-container">
    <form #candidateChecklist ngNoForm [formGroup]="_candidateService.candidateChecklistForm" (ngSubmit)="submitForm()">

        <div class="grid">
    
            <div class="col-12">
    
                <div class="grid">
    
                    <div class="col-4">
                        <div class="grid">
    
                            <div class="col-5">
                                <div class="emt-label">Delivery Type</div>
                            </div>
                            <div class="col-7">
                                <div class="emt-field">
                                    <dx-select-box id="delivery_type" formControlName="delivery_type"
                                        [items]="deliveryTypes" displayExpr="label" valueExpr="value" [value]="_candidateService.candidateChecklistForm.get('delivery_type').value">
                                    </dx-select-box>
                                </div>
                            </div>
    
                            <div class="col-5">
                                <div class="emt-label">Date Techpack Sent</div>
                            </div>
                            <div class="col-7">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="date_techpack_sent" formControlName="date_techpack_sent"
                                        [value]="_candidateService.candidateChecklistForm.get('date_techpack_sent').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
    
                            <div class="col-5">
                                <div class="emt-label">Date of Birth</div>
                            </div>
                            <div class="col-7">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="date_of_birth" formControlName="date_of_birth"
                                        [value]="_candidateService.candidateChecklistForm.get('date_of_birth').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
    
                            <div class="col-5">
                                <div class="emt-label">Social Security Number</div>
                            </div>
                            <div class="col-7">
                                <div class="emt-field">
                                    <dx-text-box id="social_security_number" formControlName="social_security_number"
                                        value="{{ _candidateService.candidateChecklistForm.get('social_security_number').value }}">
                                    </dx-text-box>
                                </div>
                            </div>
    
                        </div>
    
                    </div>
                    <div class="col-4">

                        <div class="grid">

                            <div class="col-5">
                                <div class="emt-label">Certifications Verified</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="certifications_verified" formControlName="certifications_verified"
                                        [value]="_candidateService.candidateChecklistForm.get('certifications_verified').value"
                                        (onValueChanged)="setReceivedDate($event,'certifications_verified')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="certifications_verified_date"
                                        formControlName="certifications_verified_date"
                                        [value]="_candidateService.candidateChecklistForm.get('certifications_verified_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>

                            <div class="col-5">
                                <div class="emt-label">Higher Education Verified</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="higher_education_verified" formControlName="higher_education_verified"
                                        [value]="_candidateService.candidateChecklistForm.get('higher_education_verified').value"
                                        (onValueChanged)="setReceivedDate($event,'higher_education_verified')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="higher_education_verified_date"
                                        formControlName="higher_education_verified_date"
                                        [value]="_candidateService.candidateChecklistForm.get('higher_education_verified_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>

                            <ng-container *ngIf="_candidateService.candidateChecklistForm.get('higher_education_verified').value">
                                <div class="col-5">
                                    <div class="emt-label">How was Education Verified?</div>
                                </div>
                                <div class="col-7">
                                    <div class="emt-field">
                                        <dx-select-box id="how_higher_education_verified" formControlName="how_higher_education_verified"
                                            [items]="educationVerifyTypes" displayExpr="label" valueExpr="value" [value]="_candidateService.candidateChecklistForm.get('how_higher_education_verified').value">
                                        </dx-select-box>
                                    </div>
                                </div>

                                <div class="col-5">
                                    <div class="emt-label">University Representative Name</div>
                                </div>
                                <div class="col-7">
                                    <div class="emt-field">
                                        <dx-text-box id="university_representitive_name" formControlName="university_representitive_name"
                                            value="{{ _candidateService.candidateChecklistForm.get('university_representitive_name').value }}">
                                        </dx-text-box>
                                    </div>
                                </div>
                            </ng-container>

                        </div>

                    </div>
                    <div class="col-4">

                        <div class="grid">

                            <div class="col-12">
                                <dx-button
                                    stylingMode="contained"
                                    text="Request TS Number"
                                    type="default"
                                    [width]="160"
                                    (onClick)="requestTsNumber($event)"
                                >
                                </dx-button>
                            </div>

                            <div class="col-5">
                                <div class="emt-label">TS number Requested</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="ts_number_requested" formControlName="ts_number_requested"
                                        [value]="_candidateService.candidateChecklistForm.get('ts_number_requested').value"
                                        (onValueChanged)="setReceivedDate($event,'ts_number_requested')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="ts_number_requested_date"
                                        formControlName="ts_number_requested_date"
                                        [value]="_candidateService.candidateChecklistForm.get('ts_number_requested_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
    
            <div class="col-12 n-section-header">
                <h5>Documents Received</h5>
            </div>
    
            <div class="col-12">
                <div class="grid">
    
                    <div class="col-4">
                        <div class="grid">
        
                            <div class="col-5">
                                <div class="emt-label">Signed Application<i class="far fa-asterisk ml-1 text-danger text-sm" aria-hidden="true"></i></div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="signed_application_received" formControlName="signed_application_received"
                                        [value]="_candidateService.candidateChecklistForm.get('signed_application_received').value"
                                        (onValueChanged)="setReceivedDate($event,'signed_application_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="signed_application_received_date"
                                        formControlName="signed_application_received_date"
                                        [value]="_candidateService.candidateChecklistForm.get('signed_application_received_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Code of Ethics<i class="far fa-asterisk ml-1 text-danger text-sm" aria-hidden="true"></i></div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="code_of_ethics_received" formControlName="code_of_ethics_received"
                                        [value]="_candidateService.candidateChecklistForm.get('code_of_ethics_received').value"
                                        (onValueChanged)="setReceivedDate($event,'code_of_ethics_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="code_of_ethics_received_date"
                                        formControlName="code_of_ethics_received_date"
                                        [value]="_candidateService.candidateChecklistForm.get('code_of_ethics_received_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Non-Disclosure Agreement<i class="far fa-asterisk ml-1 text-danger text-sm" aria-hidden="true"></i></div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="nda_received" formControlName="nda_received"
                                        [value]="_candidateService.candidateChecklistForm.get('nda_received').value"
                                        (onValueChanged)="setReceivedDate($event,'nda_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="nda_received_date" formControlName="nda_received_date"
                                        [value]="_candidateService.candidateChecklistForm.get('nda_received_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Registration Agreement<i class="far fa-asterisk ml-1 text-danger text-sm" aria-hidden="true"></i></div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="registration_agreement_received"
                                        formControlName="registration_agreement_received"
                                        [value]="_candidateService.candidateChecklistForm.get('registration_agreement_received').value"
                                        (onValueChanged)="setReceivedDate($event,'registration_agreement_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="registration_agreement_received_date"
                                        formControlName="registration_agreement_received_date"
                                        [value]="_candidateService.candidateChecklistForm.get('registration_agreement_received_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Ethical Practices Agreement<i class="far fa-asterisk ml-1 text-danger text-sm" aria-hidden="true"></i></div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="ethical_practices_received" formControlName="ethical_practices_received"
                                        [value]="_candidateService.candidateChecklistForm.get('ethical_practices_received').value"
                                        (onValueChanged)="setReceivedDate($event,'ethical_practices_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="ethical_practices_received_date"
                                        formControlName="ethical_practices_received_date"
                                        [value]="_candidateService.candidateChecklistForm.get('ethical_practices_received_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                        </div>
        
        
                    </div>
                    <div class="col-4">
        
                        <div class="grid">
        
                            <div class="col-5">
                                <div class="emt-label">I9</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="i9_received" formControlName="i9_received"
                                        [value]="_candidateService.candidateChecklistForm.get('i9_received').value"
                                        (onValueChanged)="setReceivedDate($event,'i9_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="i9_received_date" formControlName="i9_received_date"
                                        [value]="_candidateService.candidateChecklistForm.get('i9_received_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">W4</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="w4_received" formControlName="w4_received"
                                        [value]="_candidateService.candidateChecklistForm.get('w4_received').value"
                                        (onValueChanged)="setReceivedDate($event,'w4_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="w4_received_date" formControlName="w4_received_date"
                                        [value]="_candidateService.candidateChecklistForm.get('w4_received_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Notarized Support Documents</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="support_docs_notarized" formControlName="support_docs_notarized"
                                        [value]="_candidateService.candidateChecklistForm.get('support_docs_notarized').value"
                                        (onValueChanged)="setReceivedDate($event,'support_docs_notarized')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="support_docs_notarized_date" formControlName="support_docs_notarized_date"
                                        [value]="_candidateService.candidateChecklistForm.get('support_docs_notarized_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                                
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Resume</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="resume_received" formControlName="resume_received"
                                        [value]="_candidateService.candidateChecklistForm.get('resume_received').value"
                                        (onValueChanged)="setReceivedDate($event,'resume_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="resume_received_date" formControlName="resume_received_date"
                                        [value]="_candidateService.candidateChecklistForm.get('resume_received_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Professional References</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="i9_received" formControlName="professional_references_received"
                                        [value]="_candidateService.candidateChecklistForm.get('professional_references_received').value"
                                        (onValueChanged)="setReceivedDate($event,'professional_references_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="professional_references_received_date"
                                        formControlName="professional_references_received_date"
                                        [value]="_candidateService.candidateChecklistForm.get('professional_references_received_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                        </div>
        
        
        
                    </div>
                    <div class="col-4">
        
                        <div class="grid">
        
                            <div class="col-5">
                                <div class="emt-label">Previous Employment History</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="previous_employment_history_received"
                                        formControlName="previous_employment_history_received"
                                        [value]="_candidateService.candidateChecklistForm.get('previous_employment_history_received').value"
                                        (onValueChanged)="setReceivedDate($event,'previous_employment_history_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="previous_employment_history_received_date"
                                        formControlName="previous_employment_history_received_date"
                                        [value]="_candidateService.candidateChecklistForm.get('previous_employment_history_received_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Direct Deposit</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box id="direct_deposit_form_received"
                                        formControlName="direct_deposit_form_received"
                                        [value]="_candidateService.candidateChecklistForm.get('direct_deposit_form_received').value"
                                        (onValueChanged)="setReceivedDate($event,'direct_deposit_form_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box type="date" id="direct_deposit_form_received_date"
                                        formControlName="direct_deposit_form_received_date"
                                        [value]="_candidateService.candidateChecklistForm.get('direct_deposit_form_received_date').value | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            
        
                        </div>
        
                    </div>
    
                </div>
                
    
            </div>
    
        </div>
    
    </form>
</div>
