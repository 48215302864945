import { effect, Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SignalService {

  counter = signal(0);

  page_title = signal('');
  page_icon = signal('');
  sub_page_title = signal('');
  open_candidate_detail_drawer = signal(false);

  clog = signal('');

  constructor() {

    effect(() => {
      const log = this.clog();
      console.log(`Clog updated to ${log}`);
    });



  }


}
