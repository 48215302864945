<dx-data-grid #recordGrid id="recordGrid" [dataSource]="ds_recordList" width="100%" [noDataText]="dataGridPlaceHolder"
    [showBorders]="true" [showRowLines]="true" [rowAlternationEnabled]="true" class="dx-grid-custom">
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <!-- <dxo-search-panel [visible]="true" [text]="'filters.filterText'"></dxo-search-panel> -->
    <dxi-column caption="" cellTemplate="detailTemplate" width="60px">
        <div *dxTemplate="let data of 'detailTemplate'">
            <sl-button variant="text" size="medium" class="mt--7 mb--7" (click)="_candidateService.getCandidateDetail(data.data); openDrawer();"><i class="far fa-memo-circle-info"></i></sl-button>
        </div>
    </dxi-column>
    <dxi-column dataField="name" dataType="string" caption="Name" width="200" alignment="left"></dxi-column>
    <dxi-column dataField="address" caption="Address" alignment="left" cellTemplate="addressTemplate">
        <div *dxTemplate="let data of 'addressTemplate'">
            <div class="grid">
                <div class="col-12">{{data.data.address}}</div>
            </div>
        </div>
    </dxi-column>
</dx-data-grid>