export class CandidateDetail {
    id: number;
    ssn: string;
    first_name: string;
    last_name: string;
    add1: string;
    add2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    phone: string;
    cell: string;
    pager: string;
    fax: string;
    email: string;
    resume_text: string;
    tech_pack: string;
    requested_by_id: string;
    date_requested: string;
    tech_pack_link: string;
    date_sent: string;
    status: string;
    comments: string;
    requested_by_name: string;
}