import { CommonModule } from '@angular/common';
import { Component, Injector, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidateService } from './candidate.service';
import { DxButtonModule, DxCheckBoxModule, DxDateBoxModule, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';

@Component({
  selector: 'dcar-candidate-checklist',
  standalone: true,
  imports: [
            CommonModule,
            FormsModule, 
            ReactiveFormsModule, 
            DxTextBoxModule, 
            DxDateBoxModule,
            DxSelectBoxModule,
            DxCheckBoxModule,
            DxButtonModule
          ],
  templateUrl: './candidate-checklist.component.html',
  styleUrl: './candidate-checklist.component.css'
})
export class CandidateChecklistComponent extends AppComponentBase {
  @ViewChild('candidateChecklist') candidateChecklist
  
  controlsDisabled = true;
  deliveryTypes: { value: string, label: string }[] = []; 
  educationVerifyTypes: { value: string, label: string }[] = []; 
  
  constructor(
    injector: Injector,
    public _candidateService: CandidateService,
    
    
  ) {
    super(injector);
  }

  ngOnInit() {
    this.deliveryTypes = this.staticDataService.getDeliveryTypes();
    this.educationVerifyTypes = this.staticDataService.educationVerifyTypes();
  }

  submitForm() {
    this.candidateChecklist.nativeElement.submit();
  }

  changeVal(e) {
    console.log('e',e);
  }

 

  setReceivedDate(e,doc) {

    let value: any;
    value = e.value? new Date(): null;
      
      switch (doc) {
        case 'ts_number_requested':
          this._candidateService.candidateChecklistForm.controls['ts_number_requested_date'].setValue(this.formatDate(value));
          break;
        case 'signed_application_received':
          this._candidateService.candidateChecklistForm.controls['signed_application_received_date'].setValue(this.formatDate(value));
          break;
        case 'code_of_ethics_received':
          this._candidateService.candidateChecklistForm.controls['code_of_ethics_received_date'].setValue(this.formatDate(value));
          break;
        case 'nda_received':
          this._candidateService.candidateChecklistForm.controls['nda_received_date'].setValue(this.formatDate(value));
          break;
        case 'registration_agreement_received':
          this._candidateService.candidateChecklistForm.controls['registration_agreement_received_date'].setValue(this.formatDate(value));
          break;
        case 'ethical_practices_received':
          this._candidateService.candidateChecklistForm.controls['ethical_practices_received_date'].setValue(this.formatDate(value));
          break;
        case 'i9_received':
          this._candidateService.candidateChecklistForm.controls['i9_received_date'].setValue(this.formatDate(value));
          break;
        case 'w4_received':
          this._candidateService.candidateChecklistForm.controls['w4_received_date'].setValue(this.formatDate(value));
          break;
        case 'resume_received':
          this._candidateService.candidateChecklistForm.controls['resume_received_date'].setValue(this.formatDate(value));
          break;
        case 'professional_references_received':
          this._candidateService.candidateChecklistForm.controls['professional_references_received_date'].setValue(this.formatDate(value));
          break;
        case 'previous_employment_history_received':
          this._candidateService.candidateChecklistForm.controls['previous_employment_history_received_date'].setValue(this.formatDate(value));
          break;
        case 'higher_education_verified':
          this._candidateService.candidateChecklistForm.controls['higher_education_verified_date'].setValue(this.formatDate(value));
          
          if(!value){
            this._candidateService.candidateChecklistForm.controls['how_higher_education_verified'].setValue('');
            this._candidateService.candidateChecklistForm.controls['university_representitive_name'].setValue('');
          }

          break;
        case 'direct_deposit_form_received':
          this._candidateService.candidateChecklistForm.controls['direct_deposit_form_received_date'].setValue(this.formatDate(value));
          break;
        case 'certifications_verified':
          this._candidateService.candidateChecklistForm.controls['certifications_verified_date'].setValue(this.formatDate(value));
          break;
        case 'support_docs_notarized':
          this._candidateService.candidateChecklistForm.controls['support_docs_notarized_date'].setValue(this.formatDate(value));
          break;
           
        default:
          break;
    }

    
  }

  private formatDate(value: Date): string {
    if (!value) {
      return null;
    }
    const date = new Date(value);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  requestTsNumber(e) {

  }

}
