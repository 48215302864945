
<form #candForm ngNoForm [formGroup]="_candidateService.candidateDetailForm" (ngSubmit)="submitForm()">
    <div class="grid">
        @if (_candidateService.techPackRequestedByName != '') {
        <div class="col-12">
            <span>
                <h5 class="text-danger">A Tech Pack has been requested by {{_candidateService.techPackRequestedByName}}</h5>
            </span> 
        </div>
        }
        <div class="col-4">
            <div class="form-group">
                <label for="id">ID</label>
                <sl-input id="id" formControlName="id"
                    value="{{ _candidateService.candidateDetailForm.get('id').value }}" filled disabled></sl-input>
            </div>
            <div class="form-group">
                <label for="ssn">SSN</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="ssn" formControlName="ssn"
                    value="{{ _candidateService.candidateDetailForm.get('ssn').value }}" type="text"></sl-input>
            </div>
            <div class="form-group">
                <label for="first_name">First Name</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="first_name" formControlName="first_name"
                    value="{{ _candidateService.candidateDetailForm.get('first_name').value }}" type="text"></sl-input>
            </div>
            <div class="form-group">
                <label for="last_name">Last Name</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="last_name" formControlName="last_name"
                    value="{{ _candidateService.candidateDetailForm.get('last_name').value }}" type="text"></sl-input>
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="email" formControlName="email"
                    value="{{ _candidateService.candidateDetailForm.get('email').value }}" type="email"></sl-input>
            </div>
            <div class="form-group">
                <label for="phone">Phone</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="phone" formControlName="phone"
                    value="{{ _candidateService.candidateDetailForm.get('phone').value }}" type="text"></sl-input>
            </div>
            <div class="form-group">
                <label for="cell">Cell</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="cell" formControlName="cell"
                    value="{{ _candidateService.candidateDetailForm.get('cell').value }}" type="text"></sl-input>
            </div>
            <div class="form-group">
                <label for="pager">Pager</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="pager" formControlName="pager"
                    value="{{ _candidateService.candidateDetailForm.get('pager').value }}" type="text"></sl-input>
            </div>
            <div class="form-group">
                <label for="fax">Fax</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="fax" formControlName="fax"
                    value="{{ _candidateService.candidateDetailForm.get('fax').value }}" type="text"></sl-input>
            </div>
        </div>

        <div class="col-4">
            <div class="form-group">
                <label for="add1">Address 1</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="add1" formControlName="add1"
                    value="{{ _candidateService.candidateDetailForm.get('add1').value }}" type="text"></sl-input>
            </div>
            <div class="form-group">
                <label for="add2">Address 2</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="add2" formControlName="add2"
                    value="{{ _candidateService.candidateDetailForm.get('add2').value }}" type="text"></sl-input>
            </div>
            <div class="form-group">
                <label for="city">City</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="city" formControlName="city"
                    value="{{ _candidateService.candidateDetailForm.get('city').value }}" type="text"></sl-input>
            </div>
            <div class="form-group">
                <label for="state">State</label>
                <sl-select [attr.disabled]="controlsDisabled == true? true: null" id="state" formControlName="state" value="{{ _candidateService.candidateDetailForm.get('state').value }}">
                    <sl-option value="0">Please select</sl-option>
                @for (item of states; track item) {
                    <sl-option value="{{item.code}}">{{item.name}}</sl-option>
                }
                </sl-select>
                <!-- <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="state" formControlName="state"
                    value="{{ _candidateService.candidateDetailForm.get('state').value }}" type="text"></sl-input> -->
            </div>
            <div class="form-group">
                <label for="zip">Zip</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="zip" formControlName="zip"
                    value="{{ _candidateService.candidateDetailForm.get('zip').value }}" type="text"></sl-input>
            </div>
            <div class="form-group">
                <label for="country">Country</label>
                <sl-select [attr.disabled]="controlsDisabled == true? true: null" id="country" (ngModelChange)="getStates(_candidateService.candidateDetailForm.get('country').value)" formControlName="country" value="{{ _candidateService.candidateDetailForm.get('country').value }}">
                    <sl-option value="0">Please select</sl-option>
                @for (item of countries; track item) {
                    <sl-option value="{{item.code}}">{{item.name}}</sl-option>
                }
                </sl-select>
                <!-- <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="country" formControlName="country"
                    value="{{ _candidateService.candidateDetailForm.get('country').value }}" type="text"></sl-input> -->
            </div>
            
        </div>

        <div class="col-4">
            
            <div class="form-group">
                <label for="tech_pack">Send Tech Pack</label>
                <br>
                <sl-checkbox class="mb-4" [attr.disabled]="controlsDisabled == true? true: null"
                    [checked]="_candidateService.candidateDetailForm.get('tech_pack').value=='1'? true: false"
                    id="tech_pack" 
                    formControlName="tech_pack" 
                    size="large"
                    value="{{ _candidateService.candidateDetailForm.get('tech_pack').value }}"
                    >
                </sl-checkbox>
                
                <!-- <sl-input id="tech_pack" formControlName="tech_pack"
                    value="{{ _candidateService.candidateDetailForm.get('tech_pack').value }}" type="text"></sl-input> -->
            </div>
            <!-- <div class="form-group">
                <label for="requested_by_id">Requested By ID</label>
                <sl-input id="requested_by_id" formControlName="requested_by_id"
                    value="{{ _candidateService.candidateDetailForm.get('requested_by_id').value }}"
                    type="text"></sl-input>
            </div> -->
            <div class="form-group">
                <label for="date_requested">Date Requested</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="date_requested" formControlName="date_requested"
                    value="{{ _candidateService.candidateDetailForm.get('date_requested').value | date: 'yyyy-MM-dd' }}"
                    type="date"></sl-input>
            </div>
            <div class="form-group">
                <label for="tech_pack_link">Tech Pack Link</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="tech_pack_link" formControlName="tech_pack_link"
                    value="{{ _candidateService.candidateDetailForm.get('tech_pack_link').value }}"
                    type="text"></sl-input>
            </div>
            <div class="form-group">
                <label for="date_sent">Date Sent</label>
                <sl-input [attr.disabled]="controlsDisabled == true? true: null" id="date_sent" formControlName="date_sent"
                    value="{{ _candidateService.candidateDetailForm.get('date_sent').value | date: 'yyyy-MM-dd' }}" type="date"></sl-input>
            </div>
            <div class="form-group">
                <label for="status">Status</label>
                <sl-select [attr.disabled]="controlsDisabled == true? true: null" id="status" formControlName="status" value="{{ _candidateService.candidateDetailForm.get('status').value }}">
                    <sl-option value="0">Please select</sl-option>
                    <sl-option value="1">Not Reviewed</sl-option>
                    <sl-option value="2">Accepted-Not Assigned</sl-option>
                    <sl-option value="3">Accepted-Assigned</sl-option>
                    <sl-option value="4">Out of Scope</sl-option>
                </sl-select>
                <!-- <sl-input id="status" formControlName="status"
                    value="{{ _candidateService.candidateDetailForm.get('status').value }}" type="text"></sl-input> -->
            </div>
            <div class="form-group">
                <label for="requested_by_name">Requested By</label>
                <sl-input id="requested_by_name" formControlName="requested_by_name"
                    value="{{ _candidateService.candidateDetailForm.get('requested_by_name').value }}" filled disabled>
                </sl-input>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label for="comments">Comments</label>
                <sl-textarea [attr.disabled]="controlsDisabled == true? true: null" id="comments" formControlName="comments"
                    value="{{ _candidateService.candidateDetailForm.get('comments').value }}"></sl-textarea>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label for="resume_text">Resume Text</label>
                <p-editor [attr.disabled]="controlsDisabled == true? true: null" formControlName="resume_text" name="resume_text" [style]="{ height: '300px' }"></p-editor>
                <!-- <sl-textarea id="resume_text" formControlName="resume_text" value="{{ _candidateService.candidateDetailForm.get('resume_text').value }}"></sl-textarea>
            [(ngModel)]="htmlEditorInput" -->
            </div>
        </div>
    </div>
    <!-- <sl-button variant="primary" type="submit">Submit</sl-button>
    <sl-button variant="danger" type="button" (click)="controlsDisabled = !controlsDisabled">Toggle Disabled</sl-button> -->
</form>