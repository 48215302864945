import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidateList } from './_models/candidate-list.interface';
import { CandidateService } from './candidate.service';
import { DxDataGridModule } from 'devextreme-angular';

@Component({
  selector: 'dcar-candidate-list',
  standalone: true,
  imports: [CommonModule, DxDataGridModule],
  templateUrl: './candidate-list.component.html',
  styleUrl: './candidate-list.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CandidateListComponent extends AppComponentBase {
  ds_recordList: CandidateList[] = [];
  dataGridPlaceHolder: string = 'No candidates found';

  constructor(
    injector: Injector,
    public _candidateService: CandidateService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getCandidateList();
  }


  getCandidateList() {
    this._candidateService.getCandidateList().subscribe((result) => {
      this.ds_recordList = result;
    });
  }

  openDrawer() {
    this.signalService.open_candidate_detail_drawer.set(true);
  }
}
